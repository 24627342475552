// NOTE: 一括登録関連は学校やC4thの一括登録を参考にして、このJSは参考にしないでください
function onPreview()  {
  const submitButton = $('#submit-button');
  const fileInput = $('#file');

  submitButton.click(function() {
    common.disableControl(submitButton);

    const showError = () => {
      const url = window.location.href.split('?')[0];
      window.location.href = `${url}?error_no=1`;
    }

    // Firefox で text関数が存在しない事があったためチェック
    if(fileInput.prop('files')[0].text === null) {
      showError();
      return;
    }

    // NOTE: プレビュー後にファイルが変更されるとブラウザーのセキュリティ機能でエラーになるため、
    // 　一度JSで読み込んでみて、アクセス可能なら Form を submit する。
    fileInput.prop('files')[0].text()
      .then(function(_res) {
        $('#uploadForm').submit();
      })
      .catch(function() {
        showError();
      })
  })
}

function uploadStudentsCSV() {
  const previewButton = $('#import_preview');
  const enablePreviewButton = function() {
    previewButton.removeClass('disabled');
    previewButton.prop('disabled', false);
  }

  previewButton.on('click', function () {
    if ($('#file').val().length === 0) {
      // NOTE: 現状、ここは通らないはず
      enablePreviewButton();
      return;
    }

    previewButton.addClass('disabled');
    previewButton.prop('disabled', true);

    var form = $('#uploadForm').get()[0];
    var formData = new FormData(form);

    $('#upload-error').hide();
    $('#custom-error').hide();
    $.ajax({
      type: "POST",
      url: "/schools/settings/students/import_previews",
      dataType: "script",
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function (xhr, setting) {
        $('#progress-item').html('プレビュー表示までお待ちください')
      }
    }).fail((jqXHR) => {
      $('#upload-error').html(JSON.parse(jqXHR.responseText).error);
      $('#upload-error').show();
      enablePreviewButton();
    }).done((data, textStatus, jqXHR) => {
      $('#input-content').hide();
      enablePreviewButton();
      onPreview();
    }).always(function () {
      $('#progress-item').hide();
    });

    return false;
  });
}

function uploadTeachersCSV() {
  const previewButton = $('#import_preview');
  const enablePreviewButton = function() {
    previewButton.removeClass('disabled');
    previewButton.prop('disabled', false);
  }

  previewButton.on('click', function () {
    previewButton.addClass('disabled');
    previewButton.prop('disabled', true)
    $('#submit-button').addClass('disabled');
    $('#submit-button').prop('disabled', true);

    if ($('#file').val().length === 0) {
      enablePreviewButton();
      return;
    }

    var form = $('#uploadForm').get()[0];
    var formData = new FormData(form);

    $('#upload-error').hide()
    $('#custom-error').hide();
    $.ajax({
      type: "POST",
      url: "/schools/settings/teachers/import_previews",
      dataType: "script",
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function (xhr, setting) {
        $('#progress-item').html('プレビュー表示までお待ちください')
      },
    }).fail((jqXHR) => {
      $('#upload-error').html(JSON.parse(jqXHR.responseText).error);
      $('#upload-error').show();
      enablePreviewButton();
    }).done((data, textStatus, jqXHR) => {
      $('#progress-item').hide();

      enablePreviewButton();
      onPreview();

      $('#submit-button').prop('disabled', false);
      $('#submit-button').removeClass('disabled');
    });

    return false;
  });
}

window.uploadStudentsCSV = uploadStudentsCSV;
window.uploadTeachersCSV = uploadTeachersCSV;
window.onPreview = onPreview;
