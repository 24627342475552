function init(modalId) {
  const modal = $(`#${modalId}`);

  setButtonClickEvents(modal);
}

function setButtonClickEvents(modal) {
  modal.find('.modal-close-btn').click(function () {
    const label = getModalTitle(modal);
    const action = '閉じる'
    send_ga_event(label, action);
  });

  modal.find('.modal-primary-button').click(function () {
    const label = getModalTitle(modal);
    const action = getModalButtonText($(this));
    send_ga_event(label, action);
  });

  modal.find('.modal-secondary-button').click(function () {
    const label = getModalTitle(modal);
    const action = getModalButtonText($(this));
    send_ga_event(label, action);
  });
}

function getModalTitle(modal) {
  return modal.find('.modal-title').text().trim();
}

function getModalButtonText(clickedElement) {
  const text = clickedElement.text().trim()
  if (text) return text;

  return clickedElement.val().trim();
}

export default {
  init
}
