// NOTE: 一括登録関連は学校やC4thの一括登録を参考にして、このJSは参考にしないでください
function uploadTransferResultTxt() {
  const previewButton = $('#import_preview');
  const enablePreviewButton = function() {
    previewButton.removeClass('disabled');
    previewButton.prop('disabled', false);
  }

  previewButton.on('click', function () {
    if ($('#file').val().length === 0) {
      // NOTE: 現状、ここは通らないはず
      enablePreviewButton();
      return;
    }

    previewButton.addClass('disabled');
    previewButton.prop('disabled', true);

    const form = $('#uploadForm').get()[0];
    const formData = new FormData(form);

    $('#upload-error').hide();
    $('#custom-error').hide();
    $.ajax({
      type: 'POST',
      url: '/schools/cashless/monthly_results/import_previews',
      dataType: "script",
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function (xhr, setting) {
        $('#progress-item').html('プレビュー表示までお待ちください')
      }
    }).fail((jqXHR) => {
      $('#upload-error').html(JSON.parse(jqXHR.responseText).error);
      $('#upload-error').show();
      enablePreviewButton();
    }).done((data, textStatus, jqXHR) => {
      $('#input-content').hide();
      enablePreviewButton();
      onPreview();
    }).always(function () {
      $('#progress-item').hide();
    });

    return false;
  });
}

window.uploadTransferResultTxt = uploadTransferResultTxt;
