function limitLength(selector, maxLength) {
  $(selector).on('input', function(event) {
    const text = event.currentTarget.value;
    if (text.length > maxLength) {
      $(this).val(text.substring(0, maxLength));
    }
  });
}

export default {
  limitLength,
}
