// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
Rails.start()

require("../channels")
require("@fortawesome/fontawesome-free/js/all")

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
require('jquery-ui-dist/jquery-ui.js');
require('jquery-ui-dist/jquery-ui.css');
require('@tabler/core/dist/js/tabler.min.js');
require.context('../images', true)

import Sortable from 'sortablejs'
window.Sortable = Sortable

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing';
window.Sentry = Sentry;
window.Integrations = Integrations;

// NOTE: `@tabler/icons/icons/iconfont/tabler-icons.min` というパスで処理されてしまうので、`..`はworkaround
import '@tabler/icons/../iconfont/tabler-icons.min'

import '../styles/application.scss';
import '../src/common.js';
import '../src/absence_post.js';
import '../src/editable-rows.js';
import '../src/csv_preview.js';
import '../src/c4th_csv_preview.js';
import '../src/cashless_csv_preview.js';
import '../src/cashless_result_preview.js';
