function init() {
  changeButtonStatus();

  // Tooltips
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].forEach(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
}

function moveDown(rowID) {
  const row = $(`#row-${rowID}`);
  row.insertAfter(row.next());
  changeButtonStatus();
}

function moveUp(rowID) {
  const row = $(`#row-${rowID}`);
  row.insertBefore(row.prev());
  changeButtonStatus();
}

function addRow() {
  const newRowOriginal = $('#row-new');
  const newRow = newRowOriginal.clone();

  const rowID = crypto.randomUUID();
  newRow.attr('id', `row-${rowID}`);
  newRow.find('#btn-up').attr('onclick', `common.editableTable.moveUp('${rowID}')`);
  newRow.find('#btn-down').attr('onclick', `common.editableTable.moveDown('${rowID}')`);
  newRow.find('#btn-delete').attr('onclick', `common.editableTable.deleteRow('${rowID}')`);
  newRow.removeClass('d-none');
  newRow.insertBefore(newRowOriginal);

  // Tooltips
  new bootstrap.Tooltip(newRow.find('#btn-up'));
  new bootstrap.Tooltip(newRow.find('#btn-down'));

  changeButtonStatus();
}

function deleteRow(rowID) {
  const row = $(`#row-${rowID}`);
  row.find('#destroy').attr('value', 'true')
  row.addClass('d-none');
  changeButtonStatus();
}

function isFirst(row) {
  let elem = row.prev();
  // 非表示のカラムをスキップして、先頭かどうかのチェックをする
  while(true) {
    if (elem.length === 0) return true;

    if (!elem.hasClass('d-none')) return false;

    elem = elem.prev()
  }
}

function isLast(row) {
  let elem = row.next();
  // 非表示のカラムをスキップして、最後かどうかのチェックをする
  while(true) {
    if (elem.length === 0) return true;

    if (!elem.hasClass('d-none')) return false;

    elem = elem.next()
  }
}

function changeButtonStatus() {
  $('.tetoru-editable-table tr').each(function(i, element) {
    const elem = $(element);
    if(isFirst(elem)) {
      // 一番上の要素
      elem.find('#btn-up').addClass('disabled');
    } else {
      elem.find('#btn-up').removeClass('disabled');
    }

    if(isLast(elem)) {
      // 一番下の要素
      elem.find('#btn-down').addClass('disabled');
    } else {
      elem.find('#btn-down').removeClass('disabled');
    }
  });
}

export default {
  init,
  moveDown,
  moveUp,
  addRow,
  deleteRow
}
