import editableTable from './common/editableTable';
import modal from './common/modal';
import inputUtils from './common/inputUtils';

const enableControl = function(control) {
  control.removeClass('disabled');
  control.prop('disabled', false);
}

const disableControl = function(control) {
  control.addClass('disabled');
  control.prop('disabled', true);
}

window.common = {
  enableControl,
  disableControl,
  editableTable,
  modal,
  inputUtils
};
