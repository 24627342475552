// NOTE: 一括登録関連は学校やC4thの一括登録を参考にして、このJSは参考にしないでください
function uploadStudentsC4thCSV() {
  const updateButton = $('#upload-button');
  const enableUpdateButton = function() {
    updateButton.removeClass('disabled');
    updateButton.prop('disabled', false);
  }

  updateButton.on('click', function () {
    if ($('#file').val().length === 0) {
      // NOTE: 現状、ここは通らないはず
      enableUpdateButton();
      return;
    }

    updateButton.addClass('disabled');
    updateButton.prop('disabled', true);

    var form = $('#uploadForm').get()[0];
    var formData = new FormData(form);

    $('#upload-error').hide();
    $.ajax({
      type: "POST",
      url: "/schools/settings/students/c4th/import_previews",
      dataType: "script",
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function () {
        $('#progress-item').html('プレビュー表示までお待ちください')
      }
    }).fail((jqXHR) => {
      $('#upload-error').html(JSON.parse(jqXHR.responseText).error);
      $('#upload-error').show();
      enableUpdateButton();
    }).done((data, textStatus, jqXHR) => {
      $('#input-content').hide();
      enableUpdateButton();
      onPreview();
    }).always(function () {
      $('#progress-item').hide();
    });

    return false;
  });
}

function uploadTeacherC4thCSV() {
  const previewButton = $('#import_preview');
  const enablePreviewButton = function() {
    previewButton.removeClass('disabled');
    previewButton.prop('disabled', false);
  }

  previewButton.on('click', function () {
    previewButton.addClass('disabled');
    previewButton.prop('disabled', true);
    $('#submit-button').addClass('disabled');
    $('#submit-button').prop('disabled', true);
    if ($('#file').val().length === 0) {
      enablePreviewButton();
      return;
    }

    var form = $('#uploadForm').get()[0];
    var formData = new FormData(form);

    $('#upload-error').hide();
    $('#custom-error').hide();
    $.ajax({
      type: "POST",
      url: "/schools/settings/teachers/c4th/import_previews",
      dataType: "script",
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function (xhr, setting) {
        $('#progress-item').html('プレビュー表示までお待ちください')
      }
    }).fail((jqXHR) => {
      $('#upload-error').html(JSON.parse(jqXHR.responseText).error);
      $('#upload-error').show();
      enablePreviewButton();
    }).done((data, textStatus, jqXHR) => {
      enablePreviewButton();
      onPreview();
      $('#submit-button').removeClass('disabled');
      $('#submit-button').prop('disabled', false);
    }).always(function () {
      $('#progress-item').hide();
    });

    return false;
  });
}

window.uploadStudentsC4thCSV = uploadStudentsC4thCSV;
window.uploadTeacherC4thCSV = uploadTeacherC4thCSV;
