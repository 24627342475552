function deleteRow(link) {
  jQuery(link).parent().parent().remove();
}

function copyLatestRow() {
  var $lastRow = $(".editable-table tr:last");
  var $newRow = $lastRow.clone();
  $newRow.find(":text").val("");
  $newRow.find(":hidden").val("");
  $lastRow.find('select option').each(function (index, element) {
    $newRow.find('select option')[index].value = element.value;
  });
  $newRow.find('.delete-row').html(
    ' <a href="javascript:void(0)" class="text-red js-on-click-delete-row"><i class="ti ti-circle-minus"></i></a>'
  );
  $lastRow.after($newRow);
}

$(function(){
  $('.js-on-click-copy-latest-row').click(function () {
    copyLatestRow();
  });
  $(document).on("click", ".js-on-click-delete-row", function () {
    deleteRow(this);
  });
})
